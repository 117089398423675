/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "rebass"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Sivua ei löytynyt" />
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bg: "blueLight",
      }}
    >
      <Styled.h1>Jokin meni pieleen.</Styled.h1>
      <Styled.p>Valitettavasti etsimääsi sivua ei löytynyt.</Styled.p>
      <Button variant="primary" as={Link} to="/yhteystiedot">
        Ota meihin yhteyttä
      </Button>
    </Flex>
  </Layout>
)

export default NotFoundPage
